import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import styles from "./footer.module.css";

const Footer = () => (
  <footer className={styles.footerMain}>
    <nav className={styles.footerNav}>
      <ul className={styles.footerNavList}>
        <li className={styles.footerNavItem}>
          <OutboundLink
            className={styles.footerNavLink}
            href="https://twitter.com/EscofolioDotCom"
          >
            Twitter
          </OutboundLink>
        </li>
        <li className={styles.footerNavItem}>
          <OutboundLink
            className={styles.footerNavLink}
            href="https://github.com/LosBombz"
          >
            GitHub
          </OutboundLink>
        </li>
        <li className={styles.footerNavItem}>
          <OutboundLink
            className={styles.footerNavLink}
            href="https://www.linkedin.com/in/carlosbescobar/"
          >
            LinkedIn
          </OutboundLink>
        </li>
      </ul>
    </nav>
  </footer>
);

export default Footer;
