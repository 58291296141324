import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import styles from "./header.module.css";

const Header = ({ siteTitle }) => (
  <header className={styles.headerMain}>
    <h1 className={styles.logotype}>
      <Link to="/" className={styles.logotypeLink}>
        {siteTitle}
      </Link>
    </h1>

    {/* <nav className={styles.headerNav}>
      <ul className={styles.headerNavList}>
        <li className={styles.headerNavItem}>
          <Link className={styles.headerNavLink} to="/blog">
            Blog
          </Link>
        </li>
      </ul>
    </nav> */}
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
